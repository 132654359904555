/* Upload File */

#media-list li img {
    width: 100px;
    height: 100px
}

#media-list li {
    height: 102px
}

#media-list li video {
    width: 100px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%)
}

div#hint_brand .modal-dialog {
    top: 110px;
    width: 567px;
    max-width: 100%
}

li.myupload span {
    position: relative;
    width: 100px;
    height: 100px;
    display: block;
    background: #fff
}

li.myupload span input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}

li.myupload span i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ccc;
    font-size: 54px
}

#media-list li {
    float: left;
    border: 1px solid #ccc;
    background: #ccc;
    position: relative;
    margin: 0 5px 5px 0;
    width: 102px
}

#media-list li:last-child {
    margin-right: 0
}

.post-thumb {
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: none
}

#media-list li:hover .post-thumb {
    display: block
}

a.remove-pic {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 12px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 50%;
    display: block;
    height: 25px;
    width: 25px;
    text-align: center;
    padding: 3px 0
}

.inner-post-thumb {
    position: relative
}

.user-post-text-wrap {
    position: relative
}

.user-pic-post {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    left: 0
}

.user-pic-post img {
    width: 100%
}

.user-txt-post {
    padding: 0 0 0 65px
}

textarea.form-control.upostTextarea {
    border: 0;
    box-shadow: none;
    height: 85px;
    resize: none
}

.user-post-text-wrap {
    border-bottom: 1px solid #ccc;
    margin: 0 0 15px
}

.user-post-btn-wrap {
    margin: 25px 0 0
}

ul.btn-nav {
    list-style: none;
    padding: 0;
    margin: 0
}

ul.btn-nav li {
    position: relative;
    float: left;
    margin: 0 10px 0 0
}

ul.btn-nav li span input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 9;
    opacity: 0;
    filter: alpha(opacity=100)
}

ul#media-list {
    list-style: none;
    padding: 0;
    margin: 0
}


.input-group-addon_custom {
        width: 40px
    }

    .img_custom {
        width: 60px;
        height: 70px;
        display: none;
    }

    #rcorners {
        border-radius: 5px;
        border: 1px solid #00B0CD;
        padding: 7px 7px 10px 30px;
        width: 170px;
    }





/* Ghost Button */

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="submit"].btn_custom,
button.btn_custom {
  cursor: pointer;
}

a.btn_custom {
  margin-right: 1em; /* remove this while use*/
  margin-bottom: 1em; /* remove this while use*/
  display: inline-block;
  outline: none;
  *zoom: 1;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-weight: 600;
  letter-spacing: 1px;
  vertical-align: middle;
  border: 1px solid;
  transition: all 0.2s ease;
  box-sizing: border-box;
  text-shadow: 0 1px 0 rgba(0,0,0,0.01);
}
.btn_custom-radius {
  border-radius: 3px;
}
.btn_custom-medium {
  font-size: 0.9375em;
  padding: 0.5375em 1.375em;
}
.btn_custom-blue {
  color: #1aafd0;
  border-color: #1aafd0;
}
.btn_custom-blue:hover {
  background: #1aafd0;
  color: #fff;
  border-color: #1aafd0;		
}