
  .limheight {
    height: 300px; /*your fixed height*/
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3; /*3 in those rules is just placeholder -- can be anything*/
  }
  .limheight li {
      display: inline-block; /*necessary*/
  }
  .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;    
}

.card_selected {
    box-shadow: 0 8px 16px 0 rgba(39, 7, 247, 1);
    transition: 0.3s; 
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(255, 196, 0, 0.9);
}


.order_row {
  background: white;
  transition: 0.3s; 
}

.order_row:hover {
  background: #e0f2ff;
}



.container {
    padding: 2px 16px;
}
.columns
{   
    -moz-column-width: 11.5em; /* Firefox */
    -webkit-column-width: 11.5em; /* webkit, Safari, Chrome */
    column-width: 11.5em;
}
/*remove standard list and bullet formatting from ul*/
.columns ul
{
    margin: 0;
    padding: 0;
    list-style-type: none;
}
/* correct webkit/chrome uneven margin on the first column*/
.columns ul li:first-child
{
    margin-top:0px;
}
.thumbnail {
  position: relative;  
  height: 200px;
  overflow: hidden;
}
.thumbnail img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}
.thumbnail img.portrait {
  width: 100%;
  height: auto;
}
.calculator{
  width:100%;
  height:50px;  
}


.payment_btn{
  width:100%;
  font-size: 40px;  
  height:100px;
  background: rgb(59, 161, 0);
  color: white;
}


.selected_item{
  background: #c0d5e4;
}
.newpadding_item{
  padding:5px;
}
.nbutton_delete{  
  width:15px;
}
.nprice_tag{
  position: absolute;
  font-size: 16px;
  top: 10px;
  right: 10px;
  vertical-align: top;
  color:#000000b3;
  line-height: 13px;
  background: #e6e6ef;
  padding: 2px 5px;
  border-radius: 2px;
}

.nprice_tag_selected{
  position: absolute;
  font-size: 16px;
  top: 10px;
  right: 10px;
  vertical-align: top;
  color: white;
  line-height: 13px;
  background: #f00410;
  padding: 2px 5px;
  border-radius: 2px;
}


.td_orderline {    
    
    display: flex;
    flex-direction: row;    
    width: 100%;
    color: #999;
    justify-content: space-evenly;
    text-overflow: ellipsis;    
}


.image_product_list_item{
  display: flex;  
  margin-left: 0px;

}


.title_product_list_item{
  display: flex;
  flex: 1;
  margin-left: 10px;
  margin-right: 30px;
}


.price_product_list_item{
  display: flex;
  width: 100px;
  font-weight: 100;
  color: rgb(44, 44, 44);
  font-size: 16px;

}


.button_num_pad{
  font-size: 12px;
}

.td_num_pad {    
    max-width: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.table_num_pad{
  width:100%;
  height:100%;
}



ul.card {
  list-style-type: none;
  padding-left: 0px;
  padding-right: 0px;
  overflow-x: hidden;
}

li.normal_row {
  background: white;
  border-bottom: 1px solid #eeeeee;
  height: 110px;
  padding-top: 2px;
}

li.selected_row {
  background: #E2EAF0;
  border-bottom: 1px solid #eeeeee;
  height: 110px;
  padding-top: 2px;
}

.qty_default{
      color: black;
  }

  .qty_more_than_one{
      color: red;
  }


  .disable_text_highlighting {
    -webkit-user-select: none; /* Webkit */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;     /* IE 10  */
    /* Currently not supported in Opera but will be soon */
    -o-user-select: none;
    user-select: none;
    }