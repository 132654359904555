#loading {
    /* position: absolute;
    z-index: 1000;
    background-color: white;
    height: 100px;
    width: 100px;
    text-align: center;
    line-height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img {
    /* position: relative;
    margin: auto;
    margin-top: 5%; */
    background: black;
          color: white;
          border-radius: 1em;
          padding: 1em;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-right: -50%;
          transform: translate(-50%, -50%)
  }
  .loader2{
      position: absolute;
      top:0px;
      right:0px;
      width:100%;
      height:100%;
      background-color:white;
      background-image:url('../../assets/loading.gif');
      background-size: 100px;
      background-repeat:no-repeat;
      background-position:center;
      z-index:10000000;
      opacity: 0.4;
      filter: alpha(opacity=0.4);
  }